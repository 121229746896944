export default {
  cake: {
    56: '0xeBc148d40313Be9C9F214d3BEB9F2ddEbeC0Ec52',
    97: '',
  },
  masterChef: {
    56: '0x3F41AeBD6Fc0782AC259b86694B455678B7E650D',
    97: '',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x3920b1642b64c5E90f0323e776619Fb89d56269d',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
}
